
import * as cloudinaryRuntime$JCah39J1vx from '/Users/nficano/github/teachmehipaa/web/node_modules/@nuxt/image-edge/dist/runtime/providers/cloudinary'
import * as ipxRuntime$8PjFu8iFyS from '/Users/nficano/github/teachmehipaa/web/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$JCah39J1vx, defaults: {"baseURL":"https://res.cloudinary.com/ddvdfkf1o/image/upload/v1690384561","cloud_name":"ddvdfkf1o","api_key":"123257275757142","api_secret":"9qMNZj7N2pB6iStNMVd4f2pdp-Q"} },
  ['ipx']: { provider: ipxRuntime$8PjFu8iFyS, defaults: {} }
}
        